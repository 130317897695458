import { Col, Form, Row, Tour } from "antd";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import GetVariableValues from "../../../components/custom/common/GetVariableValues";
import Input from "../../../components/custom/input/input/Input";
import Select from "../../../components/custom/input/select/Select";
import { AppContext } from "../../../context/AppContext";
import useTemplates from "../../../hooks/useTemplates";
import { failureNotification, successNotification } from "../../utils/CommonNotifications";
import { capitalizeFirstLetter, getRandomNumber, getRegexForFindingPlaceHolderWithDigitsOnly } from "../../utils/commonUtils";
import { NoChannelPresent } from "../../utils/CommonVessels";
import { WHATSAPP_DISPLAY_NAME } from "../../../constants/AppConstants";

const NO_WHATSAPP_TEMPLATES_FOUND_ERR_MSG = `There are no templates for the selected account. Kindly go to the ${WHATSAPP_DISPLAY_NAME} account and create a template`;

const AddWhatsappTemplateDrawer = React.memo(({ selectedTemplate, setSelectedTemplate, addWhatsappTemplateForm, friendlyName }) => {
  const [appProperties] = useContext(AppContext);
  const [whatsappObject] = useState(
    appProperties?.installedApps?.filter((installedApp) => installedApp?.right?.service_name === "whatsapp")?.[0]
  );

  const [templateOptions, setTemplateOptions] = useState([]);
  const [moduleOptions, setModuleOptions] = useState([]);
  const [module, setModule] = useState(selectedTemplate?.moduleName);

  const [currentVariableValues, setCurrentVariableValues] = useState();

  const [templateLoading, setTemplateLoading] = useState(false);
  const [moduleLoading, setModuleLoading] = useState(false);

  const [openValueGetterModal, setOpenValueGetterModal] = useState(false);

  const { fetchTemplatesByChannel, fetchTemplateById, fetchModules } = useTemplates();

  const callFetchWhatsappTemplates = useCallback(async () => {
    setTemplateLoading(true);

    fetchTemplatesByChannel(whatsappObject?.integProps?.integId, appProperties, "whatsapp")
      .then((res) => {
        if (res?.success) {
          let templateData = res?.data;
          if (templateData) {
            templateData = templateData.map((template) => {
              return {
                value: template?.remoteTemplateId,
                label: template?.remoteTemplateName,
                data: template,
              };
            });

            if (templateData?.length <= 0) {
              throw new Error(NO_WHATSAPP_TEMPLATES_FOUND_ERR_MSG);
            }
            setTemplateOptions(templateData);
          } else {
            throw new Error(NO_WHATSAPP_TEMPLATES_FOUND_ERR_MSG);
          }
        } else {
          throw new Error(NO_WHATSAPP_TEMPLATES_FOUND_ERR_MSG);
        }
      })
      .catch((e) => {
        failureNotification(e.message);
      })
      .finally(() => setTemplateLoading(false));
  }, [appProperties, fetchTemplatesByChannel, whatsappObject?.integProps?.integId]);

  const handleModuleSelect = (selectedModule) => {
    setModule(selectedModule);
  };

  const callFetchModules = useCallback(async () => {
    setModuleLoading(true);
    const response = await fetchModules(appProperties, false);
    const parsedResponse = response?.data?.map((moduleName) => {
      return {
        label: capitalizeFirstLetter(moduleName),
        value: moduleName,
        data: moduleName,
      };
    });
    setModuleOptions(parsedResponse);
    setModuleLoading(false);
  }, [appProperties, fetchModules]);

  useEffect(() => {
    if (whatsappObject?.right?.auth?.authorized) {
      callFetchWhatsappTemplates();
      callFetchModules();
    }

    if (friendlyName) {
      addWhatsappTemplateForm.setFieldValue("friendlyName", friendlyName);
    }

    if (module) {
      addWhatsappTemplateForm.setFieldValue("selectedModule", module);
    }
  }, [appProperties, callFetchModules, callFetchWhatsappTemplates, fetchTemplatesByChannel, whatsappObject]);

  const handleTemplateSelect = async (selectedTemplateId) => {
    const templateObject = templateOptions?.find((item) => item?.value === selectedTemplateId)?.data;
    const { integId, remoteTemplateId, remoteTemplateName, whatsappBusinessAccountId } = templateObject;

    setTemplateLoading(true);
    const response = await fetchTemplateById(
      integId,
      remoteTemplateId,
      remoteTemplateName,
      "whatsapp",
      appProperties,
      whatsappBusinessAccountId
    );

    setTemplateLoading(false);

    setSelectedTemplate(response);
  };

  if (!whatsappObject?.right?.auth?.authorized) {
    return (
      <NoChannelPresent
        message={`${WHATSAPP_DISPLAY_NAME} channel is not connected`}
        buttonText={"Add channel"}
        baseUrl={"/channels/whatsapp"}
        newTab={false}
      />
    );
  }
  return (
    <div className="d-grid gap-4">
      <Form form={addWhatsappTemplateForm} className="d-grid gap-3">
        <Row className="mt-3" justify={"center"}>
          <Col span={12}>
            <div className="px-1">
              <Select
                customProps={{
                  placeholder: "Select Template",
                  popupMatchSelectWidth: 410,
                  onSelect: handleTemplateSelect,
                  value: selectedTemplate,
                }}
                selectSource={"Templates"}
                name={"selectedTemplate"}
                loading={templateLoading}
                options={templateOptions}
                label={"Template"}
                defaultValue={selectedTemplate?.remoteTemplateId ?? selectedTemplate?.templateId}
                searchPlaceHolder={"Search Template"}
                required={true}
              />
            </div>
          </Col>
          <Col span={12}>
            <div className="px-1">
              <Input
                formItemName={"friendlyName"}
                formItemLabel={"Friendly name"}
                placeHolder={"Enter a friendly name"}
                autoFocus={false}
                required={true}
                defaultValue={friendlyName}
                value={friendlyName}
              />
            </div>
          </Col>
        </Row>
        <Select
          customProps={{
            placeholder: "Select Module",
            popupMatchSelectWidth: 410,
            bordered: null,
            onSelect: async (e) => {
              handleModuleSelect(e);
            },
          }}
          defaultValue={module}
          selectSource={"Modules"}
          options={moduleOptions}
          label={"Module"}
          name={"selectedModule"}
          searchPlaceHolder={"Search Module"}
          loading={moduleLoading}
          required={true}
        />
        <WhatsappPreviewArea
          selectedTemplate={selectedTemplate}
          module={module}
          openValueGetterModal={openValueGetterModal}
          setOpenValueGetterModal={setOpenValueGetterModal}
          appProperties={appProperties}
          setCurrentVariableValues={setCurrentVariableValues}
          currentVariableValues={currentVariableValues}
          setSelectedTemplate={setSelectedTemplate}
          moduleLoading={moduleLoading}
          setModuleLoading={setModuleLoading}
          templateLoading={templateLoading}
        />
      </Form>
    </div>
  );
});

function WhatsappPreviewArea({
  module,
  selectedTemplate,
  openValueGetterModal,
  setOpenValueGetterModal,
  appProperties,
  setCurrentVariableValues,
  currentVariableValues,
  setSelectedTemplate,
  moduleLoading,
  setModuleLoading,
  templateLoading,
}) {
  const [fields, setFields] = useState();
  const { fetchFields } = useTemplates();
  const [fieldsLoading, setFieldsLoading] = useState(moduleLoading);

  useEffect(() => {
    const fetchDataResponse = async () => {
      return await fetchFields(appProperties, module);
    };
    setFieldsLoading(true);
    fetchDataResponse()
      .then((response) => {
        if (response?.success) {
          let responseData = response?.fields;
          if (responseData) {
            responseData = responseData.map((item) => {
              return {
                label: item?.fieldName,
                value: item?.fieldApiName,
                ...item,
              };
            });
          } else {
            responseData = [];
          }

          setFields(responseData);
        }
      })
      .finally(() => {
        setFieldsLoading(false);
      });
  }, [appProperties, fetchFields, module]);

  return (
    <div className="h-100 w-100">
      <div className="hs-h-whatsapp-preview-area rounded hs-input-box whatsapp-doodle-bg overflow-scroll">
        {module && selectedTemplate ? (
          <WhatsappComponentsContainer
            selectedTemplate={selectedTemplate}
            openValueGetterModal={openValueGetterModal}
            setOpenValueGetterModal={setOpenValueGetterModal}
            appProperties={appProperties}
            module={module}
            setCurrentVariableValues={setCurrentVariableValues}
            currentVariableValues={currentVariableValues}
            moduleLoading={moduleLoading}
            setModuleLoading={setModuleLoading}
            templateLoading={templateLoading}
            fields={fields}
            fieldsLoading={fieldsLoading}
            setSelectedTemplate={setSelectedTemplate}
          />
        ) : (
          <div className="d-grid h-100 align-items-center justify-content-center hs-placeholder-text">
            {selectedTemplate ? <>Select module to configure</> : <>Select template to configure</>}
          </div>
        )}
      </div>
    </div>
  );
}

function WhatsappComponentsContainer({
  selectedTemplate,
  openValueGetterModal,
  setOpenValueGetterModal,
  setCurrentVariableValues,
  currentVariableValues,
  setSelectedTemplate,
  fields,
  fieldsLoading,
}) {
  const [getVariableValuesForm] = Form.useForm();
  const elementsRef = useRef({});
  const allDisplayTextConjoinedString = selectedTemplate?.components?.map((component) => component.value).join(" ");
  const totalNumberOfVariablesPresentLength = useMemo(
    () => allDisplayTextConjoinedString?.match(getRegexForFindingPlaceHolderWithDigitsOnly())?.length ?? 0,
    [allDisplayTextConjoinedString]
  );

  const totalNumberOfVariablesPresent = useRef(totalNumberOfVariablesPresentLength);

  const handleOnClose = () => {
    setOpenValueGetterModal(false);
  };

  const handleOnOpen = (value, name) => {
    setCurrentVariableValues({ value: value, name: name });
    setOpenValueGetterModal(true);
  };

  const doBeforeClose = () => {
    handleOnClose();
    getVariableValuesForm.resetFields();
  };

  const handleOnModalSave = async () => {
    try {
      const getVariableValuesFormValues = await getVariableValuesForm.validateFields();
      const index = parseInt(currentVariableValues?.value ?? 1) - 1;
      setSelectedTemplate((prev) => {
        const componentIndex = prev?.components?.findIndex((component) => currentVariableValues.name === component.type);
        if (componentIndex !== -1) {
          prev.components[componentIndex].values = prev?.components?.[componentIndex]?.values ?? [];
          prev.components[componentIndex].values[index] = prev?.components?.[componentIndex]?.values?.[index] ?? {};
          prev.components[componentIndex].values[index].field = getVariableValuesFormValues?.selectedField;
          prev.components[componentIndex].values[index].defaultValue = getVariableValuesFormValues?.defaultValue;
          successNotification("Saved successfully!");
        }
        return prev;
      });
      doBeforeClose();
    } catch (e) {
      return false;
    }
  };

  const variableGetterModal = useRef();
  console.log("variableGetterModal , ", variableGetterModal);

  return (
    <>
      <Tour
        open={openValueGetterModal}
        rootClassName="w-auto"
        onClose={handleOnClose}
        closeIcon={<></>}
        steps={[
          {
            title: (
              <GetVariableValues
                okButtonProps={{
                  htmlType: "submit",
                  buttonText: "Save",
                  onClick: handleOnModalSave,
                }}
                cancelButtonProps={{
                  buttonText: "Cancel",
                  onClick: doBeforeClose,
                }}
                selectSource={"fields"}
                fields={fields}
                getVariableValuesForm={getVariableValuesForm}
              />
            ),
            target: () => elementsRef.current[`var-ref-${currentVariableValues?.name}-${currentVariableValues?.value}`],
            placement: "topLeft",
          },
        ]}
        indicatorsRender={() => null}
      />
      <div className="d-grid gap-2 p-1 px-3">
        {selectedTemplate?.components?.map((component, index) => {
          return (
            <WhatsappComponent
              totalNumberOfVariablesPresent={totalNumberOfVariablesPresent}
              fieldsLoading={fieldsLoading}
              handleOnOpen={handleOnOpen}
              fields={fields}
              selectedTemplate={selectedTemplate}
              handleOnClose={doBeforeClose}
              currentVariableValues={currentVariableValues}
              name={component?.type?.toLowerCase()}
              key={getRandomNumber(5)}
              elementsRef={elementsRef}
              componentIndex={index}
            >
              {component?.value}
            </WhatsappComponent>
          );
        })}
      </div>
    </>
  );
}

function WhatsappComponent({ name, children, handleOnOpen, elementsRef, selectedTemplate, totalNumberOfVariablesPresent }) {
  const handleVariableClick = (selectedVariableValue) => {
    selectedVariableValue = selectedVariableValue.substring(2, selectedVariableValue.length - 2);
    if (!isNaN(selectedVariableValue)) {
      handleOnOpen(selectedVariableValue, name);
    }
  };

  const handleOnClick = useCallback(
    (part, name, e) => {
      if (handleVariableClick) {
        handleVariableClick(part, name, e);
      }
    },
    [handleVariableClick]
  );
  const renderContent = useCallback(() => {
    const parts = children?.split(/(\{\{\d+}\})/);
    let variableIndex = 0;
    return parts?.map((part, index) => {
      if (part.match(/(\{\{\d+}\})/)) {
        return (
          <button
            ref={(el) => (elementsRef.current[`var-ref-${name}-${part.substring(2, part.length - 2)}`] = el)}
            key={`var_${++variableIndex}`}
            style={{ all: "unset" }}
            className="hs-color-violet cursor-pointer"
            onClick={(e) => handleOnClick(part, name, e)}
          >
            {part}
          </button>
        );
      }
      return (
        <span className="text-wrap me-0" key={index}>
          {part}
        </span>
      );
    });
  }, [name, handleOnClick, children]);

  const validateInputFields = () => {
    const filledValuesLength = selectedTemplate?.components?.reduce((accumulator, component) => {
      return accumulator + (component?.values?.length ?? 0);
    }, 0);

    console.log("debug_alex validateInputFields filledValuesLength", filledValuesLength);
    console.log("debug_alex validateInputFields totalNumberOfVariablesPresent.current", totalNumberOfVariablesPresent.current);

    if (filledValuesLength !== totalNumberOfVariablesPresent.current) {
      return Promise.reject(new Error("Please update all values"));
    } else {
      return Promise.resolve();
    }
  };

  return (
    <Input
      validator={validateInputFields}
      name={name}
      customClasses={`bg-transparent hs-input-prefix ${name === "body" || name === "header" ? "py-3" : ""}`}
      formItemLabel={capitalizeFirstLetter(name)}
      formItemName={name}
      readOnly
      size={name === "body" || name === "header" ? "" : "m"}
      aria-readonly
      autoFocus={false}
      value={children}
      supportVariables={true}
      prefix={renderContent()}
      required={true}
    />
  );
}

export default AddWhatsappTemplateDrawer;
