import { Col, Row } from "antd";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../context/AppContext";
import AuthorizePage, { servicesImages } from "../OnboardingComponents/AuthorizePage";
import { Logo } from "../utils/CommonVessels";

const FB_API_VERSION = "v21.0";

export default function NoStepsAuthPage(props) {
  const { rightService, setRightServiceObj, setSuccessAuthModal } = props;
  const wabaId = useRef();

  useEffect(() => {
    window.fbAsyncInit = function () {
      console.log("debug_fb init called");

      window.FB.init({
        appId: process.env.REACT_APP_FB_APP_ID,
        autoLogAppEvents: true,
        xfbml: true,
        version: FB_API_VERSION,
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");

    const handleMessageEvent = (event) => {
      if (event.origin !== "https://www.facebook.com" && event.origin !== "https://web.facebook.com") return;
      try {
        const data = JSON.parse(event.data);
        if (data.type === "WA_EMBEDDED_SIGNUP" && data.event === "FINISH") {
          wabaId.current = data.data.waba_id;
        }
      } catch {}
    };

    window.addEventListener("message", handleMessageEvent);
    return () => {
      window.removeEventListener("message", handleMessageEvent);
    };
  }, []);

  const fbLoginCallback = (response) => {
    if (response.authResponse && response.authResponse.code) {
      const code = response.authResponse.code;
      const data = {
        code: code,
        wabaId: wabaId.current,
        caller: "fbLoginCallback",
      };
      postMessage(JSON.stringify(data), "*");
    }
  };

  // Launch method and callback registration
  const launchWhatsAppSignup = () => {
    window.FB.login(fbLoginCallback, {
      config_id: process.env.REACT_APP_FB_CONFIG_ID,
      response_type: "code",
      override_default_response_type: true,
      extras: {
        setup: {},
        featureType: "",
        sessionInfoVersion: "3",
      },
    });
  };

  return (
    <Row align={"top"} justify={"center"} className="w-100 hs-vh-75">
      <Row className="w-100">
        <Col span={20}>
          <MainContent
            rightService={rightService}
            setRightServiceObj={setRightServiceObj}
            setSuccessAuthModal={setSuccessAuthModal}
            onAuthorize={launchWhatsAppSignup}
          />
        </Col>
      </Row>
    </Row>
  );
}

function MainContent({ rightService, setRightServiceObj, setSuccessAuthModal, onAuthorize }) {
  const [appProperties] = useContext(AppContext);

  const { app, description, helper, note } = appProperties?.providerCommonObj ?? {};
  const appLogo = servicesImages[app + ".png"];
  return (
    <div className="p-4 d-grid gap-4">
      <Logo appLogo={appLogo} />
      <AppDescription description={description} />
      <AppHelper helper={helper} />
      <AppConnector
        setSuccessAuthModal={setSuccessAuthModal}
        rightService={rightService}
        setRightServiceObj={setRightServiceObj}
        onAuthorize={onAuthorize}
      />
      <AppNote note={note} />
    </div>
  );
}

function AppDescription({ description = "" }) {
  return <div id="nostepsauthpage-app-description">{description}</div>;
}

function AppConnector({ rightService, setRightServiceObj, setSuccessAuthModal, onAuthorize }) {
  return (
    <AuthorizePage
      onAuthorize={onAuthorize}
      authorizeButtonName="Connect"
      rightServiceObj={rightService}
      setRightServiceObj={setRightServiceObj}
      source={"noStepsAuth"}
      setSuccessAuthModal={setSuccessAuthModal}
    />
  );
}

function AppNote({ note }) {
  if (!note) {
    return <></>;
  }

  return (
    <div>
      <b>Note : </b>
      {note}
    </div>
  );
}

function AppHelper({ helper }) {
  if (!helper || !Array.isArray(helper)) {
    return <></>;
  }

  return (
    <div className="p-3 hs-border-8 hs-bg-light-blue-3">
      <ul className="ps-4 d-grid gap-2 mb-0">
        {helper.map((helperItem, index) => {
          return <li key={index}>{helperItem}</li>;
        })}
      </ul>
    </div>
  );
}
