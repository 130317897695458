import { Button, Divider, Empty, Input, Menu, Select, Skeleton, Space, Switch, Table, Tooltip, Typography, Row, Col, Spin, Tour } from "antd";

import { LoadingOutlined } from "@ant-design/icons";
import Link from "antd/es/typography/Link";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import onBoardingPhoneSwitch from "../../assets/gif/onBoardingPhoneSwitch.gif";
import { AppContext } from "../../context/AppContext";
import useHttp from "../../hooks/useHttp";
import { failureNotification, successNotification } from "../utils/CommonNotifications";
import { ConfirmAlertModal } from "../utils/CommonVessels";
import "./../../assets/css/services/configPhone.css";

function ConfigPhone({ rightServiceObj, setShowFinishButton }) {
  const { state } = useLocation();
  const ref = useRef(null);
  const [configurePhoneGifOpen, setConfigurePhoneGifOpen] = useState(false);
  const [appProperties, setAppProperties] = useContext(AppContext);
  const [selectedMenuItem, setSelectedMenuItem] = useState();
  const [phoneNumberList, setPhoneNumberList] = useState();
  const [pagesList, setPagesList] = useState([]);
  const [msgSidList, setMsgSidList] = useState([]);
  const [isSwitchLoading, setIsSwitchLoading] = useState([]);
  const [phoneData, setPhoneData] = useState([]);
  const [msgData, setMsgData] = useState([]);
  const [alphaData, setAlphaData] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [numbersApiLoading, setNumbersApiLoading] = useState(true);
  const [addBtnLoading, setAddBtnLoading] = useState(false);
  const [deletePhoneModal, setDeletePhoneModal] = useState({
    open: false,
    phoneNumber: null,
  });
  const [selectedPhone, setSelectedPhone] = useState({
    friendlyName: "",
    phoneNumber: "",
  });
  const [showEditContent, setShowEditContent] = useState(false);

  const items = appProperties.providerCommonObj?.menu;
  const integId = rightServiceObj?.integId;
  const authorized = rightServiceObj?.authorized;
  const handleMenuClick = (event) => {
    setSelectedMenuItem(event.key);
    setShowEditContent(false);
    setSelectedPhone({});
  };
  const { fetchData } = useHttp();

  useEffect(() => {
    let currentPhones = phoneData?.filter((obj) => obj?.integId === integId);
    if (currentPhones?.length > 0) {
      setConfigurePhoneGifOpen(false);
    } else {
      if (phoneNumberList?.length > 0) {
        setConfigurePhoneGifOpen(true);
      }
    }
  }, [phoneData, phoneNumberList, integId]);

  useEffect(() => {
    if (integId && authorized) {
      const fetchApi = async () => {
        setNumbersApiLoading(true);
        await fetchData(
          appProperties?.providerCommonObj?.category === "messenger" ? `omessage/${integId}/facebook/savedPages` : `helloSend/savedNumbers`,
          "GET",
          null,
          appProperties
        ).then((response) => {
          try {
            if (response) {
              const responseData = JSON.parse?.(response);

              if (responseData?.data?.data.length > 0) {
                let currentPhones = responseData?.data?.data.filter((obj) => obj?.integId === integId);
                if (currentPhones?.length > 0 || responseData?.data?.messagingServices?.length > 0) {
                  setShowFinishButton(true);
                }
                responseData.data.data.map((data) => {
                  if (data?.phoneUniqueId) {
                    setPhoneData((prevState) => [...prevState, data]);
                  } else {
                    setAlphaData((prevState) => [...prevState, data]);
                    if (appProperties?.providerCommonObj?.category === "messenger") {
                      setPageData((prevState) => [...prevState, data]);
                    }
                  }
                  return null;
                });
              } else {
                setPhoneData([]);
              }
              setMsgData(responseData?.data?.messagingServices ?? []);
            }
          } catch (error) {
            console.error("Error processing response:", error);
          }
        });
      };

      fetchApi().catch((err) => {
        console.log(err);
      });
      appProperties?.providerCommonObj?.category === "messenger" ? setSelectedMenuItem("Select Pages") : setSelectedMenuItem("Phone number");
    } else {
      setPhoneNumberList([]);
      setMsgSidList([]);
      setPagesList([]);
      setSelectedMenuItem("");
    }
  }, [authorized, integId, state, appProperties.integId, fetchData, setShowFinishButton]);

  useEffect(() => {
    if (integId && authorized) {
      const fetchnNumbersApi = async () => {
        setNumbersApiLoading(true);
        await fetchData(
          appProperties?.providerCommonObj?.category === "messenger" ? `omessage/${integId}/facebook/pages` : `omessage/${integId}/numbers`,
          "GET",
          null,
          appProperties
        )
          .then((response) => {
            try {
              if (response) {
                const responseData = JSON.parse(response);
                if (responseData?.data?.data) {
                  // setConfigurePhoneGifOpen(true);
                  let getNumberList = responseData.data.data;
                  if (typeof getNumberList === "string") {
                    getNumberList = JSON.parse(getNumberList);
                  }
                  if (appProperties?.providerCommonObj?.category !== "messenger") {
                    setPhoneNumberList(getNumberList.phone || []);
                  } else {
                    setPagesList(getNumberList);
                  }
                  setNumbersApiLoading(false);
                } else {
                  setPhoneNumberList([]);
                  setNumbersApiLoading(false);
                }
                if (responseData?.data?.messagingServices) {
                  let getMsgServicesLists = responseData.data.messagingServices;
                  const msgSidsList = getMsgServicesLists.map((service) => ({
                    friendlyName: service.friendlyName,
                    sid: service.sid,
                  }));
                  setMsgSidList(msgSidsList);
                }
              }
            } catch (error) {
              console.error("Error processing response:", error);
            }
          })
          .catch((err) => {
            setNumbersApiLoading(false);
          });
      };

      fetchnNumbersApi().catch((err) => {
        console.log(err);
      });
      appProperties?.providerCommonObj?.category === "messenger" ? setSelectedMenuItem("Select Pages") : setSelectedMenuItem("Phone number");
    } else {
      setPhoneNumberList([]);
      setMsgSidList([]);
      setPagesList([]);
      setSelectedMenuItem("");
    }
  }, [integId, authorized]);

  const handlePhoneNumberApi = (phoneNumber, switchType) => {
    if (switchType === true) {
      if (phoneNumber === "Table") {
        if (!selectedPhone?.phoneNumber) {
          failureNotification("Error", "Select a valid Messaging Service ");
          return;
        }
        setAddBtnLoading(true);
      }
      handleAddPhone(phoneNumber);
    } else if (switchType === false) {
      setIsSwitchLoading({ ...isSwitchLoading, [phoneNumber]: true });
      setDeletePhoneModal({ open: true, phoneNumber: phoneNumber });
    }
  };

  const handleAddPhone = (phoneNumber) => {
    let appData = {};
    switch (selectedMenuItem) {
      case "Phone number":
        setIsSwitchLoading({ ...isSwitchLoading, [phoneNumber]: true });
        appData = {
          phoneNumber: phoneNumber,
          friendlyName: selectedPhone?.friendlyName || "",
          isMessagingService: false,
          alphaSender: false,
          messageServiceId:
            (msgSidList?.length > 0 &&
              msgSidList?.find((obj) => {
                return obj?.friendlyName?.startsWith("HelloSend");
              })?.sid) ||
            "",
        };
        break;

      case "Select Pages":
        setIsSwitchLoading({ ...isSwitchLoading, [phoneNumber.id]: true });
        appData = {
          pageId: phoneNumber.id,
          osyncId: appProperties.osyncId,
          friendlyName: phoneNumber.name,
        };
        break;

      default:
        appData = {
          phoneNumber: selectedPhone?.phoneNumber,
          friendlyName: selectedPhone?.friendlyName,
          isMessagingService: selectedMenuItem === "MessagingService",
          alphaSender: selectedMenuItem === "AlphaSender",
        };
        break;
    }
    fetchData(
      appProperties?.providerCommonObj?.category === "messenger" ? `omessage/${integId}/facebook/savePage` : `omessage/${integId}/savePhone`,
      "POST",
      appData,
      appProperties
    ).then((response) => {
      try {
        if (response) {
          setConfigurePhoneGifOpen(false);
          let savePhoneResp = JSON.parse(response);

          let resp = savePhoneResp?.data?.data;
          if (savePhoneResp?.data?.license) {
            failureNotification(savePhoneResp?.data?.license?.code, savePhoneResp?.data?.license?.description);
            setIsSwitchLoading({ ...isSwitchLoading, [phoneNumber]: false });
            setAddBtnLoading(false);
          }
          if (savePhoneResp?.data?.errorMessage) {
            setIsSwitchLoading({ ...isSwitchLoading, [phoneNumber]: false });
            failureNotification("Error", savePhoneResp.data.errorMessage);
          } else if (savePhoneResp?.data?.status) {
            successNotification(selectedMenuItem + " added. You can now send Message to clients.");
            switch (selectedMenuItem) {
              case "Phone number":
                setPhoneData(phoneData?.length > 0 ? [...phoneData, resp] : [resp]);
                let currentPhones = phoneData?.filter((obj) => obj?.integId === integId);
                if (currentPhones?.length === 0) {
                  setShowFinishButton(true);
                }
                setIsSwitchLoading({
                  ...isSwitchLoading,
                  [phoneNumber]: false,
                });
                break;

              case "MessagingService":
                let newMsgData = {};
                if (msgData.some((obj) => obj.phoneNumber === selectedPhone.phoneNumber && resp)) {
                  newMsgData = msgData.filter((obj) => obj.phoneNumber !== selectedPhone.phoneNumber);
                }
                setMsgData(
                  msgData.some((obj) => obj.phoneNumber === selectedPhone.phoneNumber && resp)
                    ? [...newMsgData, resp]
                    : msgData?.length > 0
                    ? [...msgData, resp]
                    : [resp]
                );
                setShowFinishButton(true);
                setAddBtnLoading(false);
                setShowEditContent(false);
                setIsSwitchLoading({
                  ...isSwitchLoading,
                  [phoneNumber]: false,
                });
                break;

              case "AlphaSender":
                setAlphaData(alphaData?.length > 0 ? [...alphaData, resp] : [resp]);
                setIsSwitchLoading({
                  ...isSwitchLoading,
                  [phoneNumber]: false,
                });
                setShowEditContent(false);
                setAddBtnLoading(false);
                break;

              case "Select Pages":
                setPageData(pageData?.length > 0 ? [...alphaData, resp] : [resp]);
                setShowFinishButton(true);
                setIsSwitchLoading({
                  ...isSwitchLoading,
                  [phoneNumber.id]: false,
                });
                break;

              default:
                setIsSwitchLoading({
                  ...isSwitchLoading,
                  [phoneNumber]: false,
                });
                setIsSwitchLoading({
                  ...isSwitchLoading,
                  [phoneNumber?.id]: false,
                });
                setShowEditContent(false);
                setAddBtnLoading(false);
                break;
            }
            setAppProperties((prevApp) => ({
              ...prevApp,
              savedNumbers: [...(prevApp?.savedNumbers?.filter((obj) => obj?.phoneNumber !== phoneNumber) || []), resp],
            }));
          } else {
            setIsSwitchLoading({
              ...isSwitchLoading,
              [phoneNumber]: false,
            });
            setAddBtnLoading(false);
          }
        }
      } catch (error) {
        console.error("Error processing response:", error);
      }
    });
  };
  const handleDeletePhone = async (phoneNumber) => {
    await fetchData(
      appProperties?.providerCommonObj?.category === "messenger"
        ? `omessage/${integId}/facebook/page/${phoneNumber.id}`
        : `omessage/${integId}/deletePhone?phoneNumber=${encodeURIComponent(phoneNumber)}`,
      "DELETE",
      null,
      appProperties
    )
      .then(function (resp) {
        try {
          if (resp) {
            resp = JSON.parse(resp);
            setIsSwitchLoading({ ...isSwitchLoading, [phoneNumber]: false });
            setDeletePhoneModal((prev) => ({ ...prev, open: false }));
            if (resp?.status === 200) {
              if (appProperties?.providerCommonObj?.category === "messenger") {
                setPageData((prevPageData) => prevPageData.filter((num) => num.pageId !== phoneNumber.id));
              } else {
                setPhoneData((prevPhoneData) => prevPhoneData.filter((num) => num.phoneNumber !== phoneNumber));
              }

              let currentPhones = phoneData.filter((obj) => obj?.integId === integId && obj?.phoneNumber !== (phoneNumber || phoneNumber?.id));
              if (currentPhones?.length > 0 || msgData?.length > 0) {
                setShowFinishButton(true);
              } else {
                setShowFinishButton(false);
              }
              setAppProperties((prevApp) => ({
                ...prevApp,
                savedNumbers: prevApp?.savedNumbers?.filter((obj) => obj?.phoneNumber !== phoneNumber),
              }));
            }
          }
        } catch (error) {
          console.error("Error processing response:", error);
        }
      })
      .catch((err) => {
        setIsSwitchLoading({ ...isSwitchLoading, [phoneNumber]: false });
      });
  };

  const handleEditPhone = (phoneNumber) => {
    let queryObj = {};
    if (selectedMenuItem === "Phone number") {
      queryObj = phoneData.find((element) => element.phoneNumber === phoneNumber);
    } else if (selectedMenuItem === "MessagingService") {
      queryObj = msgData.find((element) => element.phoneNumber === phoneNumber);
    } else {
      queryObj = phoneData.find((element) => element.phoneNumber === phoneNumber);
    }

    setSelectedPhone(queryObj);
  };

  const PhoneTable = () => {
    return (
      <Table
        scroll={{ y: 350 }}
        pagination={false}
        className="phoneAddTable"
        dataSource={selectedMenuItem === "MessagingService" ? msgData : alphaData}
        columns={[
          {
            title: "Friendly Name",
            dataIndex: "friendlyName",
            key: "friendlyName",
          },

          selectedMenuItem === "MessagingService"
            ? {
                title: "Messaging Service ID",
                dataIndex: "messageServiceId",
                key: "messageServiceId",
              }
            : {
                title: "Alpha Sender ID",
                dataIndex: "phoneNumber",
                key: "phoneNumber",
              },
        ]}
      />
    );
  };
  function getFriendlyName(phoneNumber) {
    const queryObj = phoneData.find((element) => element.phoneNumber === phoneNumber);
    let friendlyName = queryObj?.friendlyName;
    return <>{friendlyName ? <span>{"(" + friendlyName + ")"}</span> : null}</>;
  }

  const doOnCancel = () => {
    setDeletePhoneModal((prev) => ({ ...prev, open: false }));
    setIsSwitchLoading({
      ...isSwitchLoading,
      [deletePhoneModal?.phoneNumber]: false,
    });
  };

  const doOnConfirm = async () => {
    await handleDeletePhone(deletePhoneModal?.phoneNumber);
  };
  const steps = [
    {
      description: (
        <>
          <img alt="onBoardingPhoneSwitch.gif" style={{ height: "70px" }} src={onBoardingPhoneSwitch} />
        </>
      ),
      placement: "right",
      target: () => ref.current,
    },
  ];
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setConfigurePhoneGifOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="d-grid gap-4">
        <div className="pt-0 hs-h-55 mt-2 ">
          <Row className="ms-4 d-flex align-items-center h-100">
            <Col className="ms-3 me-2 staticIconsSprite stepTwoAuthorizeIcon"></Col>
            <span>
              <Typography.Title
                style={{
                  color: "#374151",
                  textTransform: "capitalize",
                  fontSize: 16,
                  fontWeight: 500,
                  paddingTop: 10,
                }}
              >
                Configure Phone
              </Typography.Title>
            </span>
          </Row>
          <Divider className="w-50 ms-auto me-auto mt-1 mb-1" style={{ minWidth: "80%", borderBlockStart: "1.5px solid #F3F3F3" }} />
        </div>
        <div className={`${!items?.length > 0 ? "mt-1" : "mt-2"} `}>
          {items?.length > 0 && (
            <Menu
              className={`pt-0 pb-4 my-0 ${rightServiceObj?.rightServiceName === "twilio" ? "addPhoneMenu ms-4 ps-3" : "configPhoneMenu"}`}
              onClick={handleMenuClick}
              selectedKeys={[selectedMenuItem]}
              mode="horizontal"
              disabled={authorized === true ? false : true}
              items={items}
            />
          )}
          {selectedMenuItem && (
            <div style={{ overflow: "auto", height: "35rem" }} className="flex-grow-* scrollWidthColor">
              {selectedMenuItem === "Phone number" ? (
                <div>
                  <div className="d-flex flex-column justify-content-start align-items- ms-4 me-5 ">
                    <div className="d-flex align-items-center justify-content-between">
                      <span className="ms-3 d-flex align-items-center " style={{ color: "#030229" }}>
                        {" "}
                        List of Numbers
                        <Tooltip title="Only MMS/SMS -enabled numbers listed" placement="right">
                          <span className="actionIconsSprite number-info-sprite ms-2"></span>
                        </Tooltip>
                      </span>
                      <Tour closeIcon={false} open={configurePhoneGifOpen} onClose={() => setConfigurePhoneGifOpen(false)} steps={steps} />
                    </div>
                    {phoneNumberList?.length > 0 ? (
                      phoneNumberList.map((obj, index) => {
                        return (
                          <div
                            key={index}
                            ref={index === 0 ? ref : null}
                            className="d-flex justify-content-between  align-items-center m-3 ms-0"
                          >
                            <div className="d-flex align-items-center">
                              <div className="me-3"></div>
                              <span style={{ fontWeight: 600 }}>
                                {obj?.includes(":::") ? obj?.split(":::")[1] : obj}
                                {getFriendlyName(obj)}
                              </span>
                              {phoneData.some((element) => {
                                return element.phoneNumber === obj;
                              }) ? (
                                <div
                                  className="ms-2 editPhone phoneLogoSprites"
                                  onClick={() => {
                                    handleEditPhone(obj);
                                    setShowEditContent(true);
                                  }}
                                ></div>
                              ) : null}
                            </div>
                            <Switch
                              key={index}
                              loading={isSwitchLoading[obj]}
                              className="addPhoneSwitch"
                              size="small"
                              value={obj}
                              checked={phoneData.some((element) => {
                                return element.phoneNumber === obj;
                              })}
                              onClick={(e) => {
                                handleEditPhone(obj);
                                handlePhoneNumberApi(obj, e);
                              }}
                            />
                          </div>
                        );
                      })
                    ) : numbersApiLoading === true ? (
                      <Spin className="m-5 pt-5" paragraph={{ rows: 4 }} active indicator={<LoadingOutlined spin className="mt-5 " />} />
                    ) : (
                      // <Skeleton className="m-2" paragraph={{ rows: 4 }} active />
                      phoneNumberList?.length < 1 &&
                      numbersApiLoading === false && (
                        <Empty
                          style={{ marginTop: "30%" }}
                          description={`No ${appProperties?.providerCommonObj?.category === "messenger" ? "pages" : "numbers"} in your ${
                            rightServiceObj.rightServiceName
                          } Account.`}
                        />
                      )
                    )}
                    <div
                      style={{
                        position: "absolute",
                        left: showEditContent ? "2rem" : "100%",
                        zIndex: showEditContent ? 10 : -2,
                        background: "white",
                        width: showEditContent ? "85%" : "0%",
                        height: "60vh",
                        transition: "0.4s ease-in-out, width 0.3s ease-in",
                        overflow: "hidden",
                      }}
                    >
                      <UpdatePhone
                        selectedMenuItem={selectedMenuItem}
                        selectedPhone={selectedPhone}
                        handlePhoneNumberApi={handlePhoneNumberApi}
                        integId={integId}
                        appProperties={appProperties}
                        setShowEditContent={setShowEditContent}
                        msgSidList={msgSidList}
                        setSelectedPhone={setSelectedPhone}
                        setPhoneData={setPhoneData}
                        alphaSender={false}
                        messagingService={false}
                        setAddBtnLoading={setAddBtnLoading}
                        addBtnLoading={addBtnLoading}
                        setAppProperties={setAppProperties}
                      />
                    </div>
                  </div>
                </div>
              ) : selectedMenuItem === "MessagingService" ? (
                <>
                  <div
                    className=""
                    style={{
                      position: "absolute",
                      left: showEditContent ? "0px" : "100%",
                      paddingLeft: showEditContent ? "3rem" : "",
                      zIndex: showEditContent ? 10 : -2,
                      right: "0",
                      background: "white",
                      width: showEditContent ? "100%" : 0,
                      height: "60vh",
                      transition: "0.4s ease-in-out, width 0.3s ease-in",
                      overflow: "hidden",
                    }}
                  >
                    <UpdatePhone
                      selectedMenuItem={selectedMenuItem}
                      selectedPhone={selectedPhone}
                      integId={integId}
                      appProperties={appProperties}
                      setShowEditContent={setShowEditContent}
                      msgSidList={msgSidList}
                      setSelectedPhone={setSelectedPhone}
                      alphaSender={false}
                      handlePhoneNumberApi={handlePhoneNumberApi}
                      messagingService={true}
                      setAddBtnLoading={setAddBtnLoading}
                      addBtnLoading={addBtnLoading}
                      setAppProperties={setAppProperties}
                    />
                  </div>
                  <div className="d-flex justify-content-between align-items-center m-4 mt-3 mb-3">
                    <span className="edit-head">Added Service</span>
                    <Button
                      onClick={() => {
                        setShowEditContent(true);
                      }}
                      type=""
                      style={{ backgroundColor: "#34C759", color: "white" }}
                    >
                      Configure
                    </Button>
                  </div>

                  <PhoneTable />
                </>
              ) : selectedMenuItem === "AlphaSender" ? (
                <div>
                  <div
                    style={{
                      position: "absolute",
                      left: showEditContent ? "0px" : "100%",
                      paddingLeft: showEditContent ? "3rem" : "",
                      zIndex: showEditContent ? 10 : -2,
                      right: "0",
                      background: "white",
                      width: showEditContent ? "100%" : 0,
                      transition: "0.4s ease-in-out, width 0.3s ease-in",
                      overflow: "hidden",
                      height: "60vh",
                    }}
                  >
                    <UpdatePhone
                      handlePhoneNumberApi={handlePhoneNumberApi}
                      selectedMenuItem={selectedMenuItem}
                      selectedPhone={selectedPhone}
                      integId={integId}
                      appProperties={appProperties}
                      setShowEditContent={setShowEditContent}
                      msgSidList={msgSidList}
                      setSelectedPhone={setSelectedPhone}
                      alphaSender={true}
                      messagingService={false}
                      setAddBtnLoading={setAddBtnLoading}
                      addBtnLoading={addBtnLoading}
                      setAppPropertie={setAppProperties}
                    />
                  </div>
                  <div className="d-flex justify-content-between align-items-center m-4 mt-3 mb-3">
                    <span className="edit-head">Added Service</span>
                    <Button
                      onClick={() => {
                        setShowEditContent(true);
                      }}
                      type=""
                      style={{ backgroundColor: "#34C759", color: "white" }}
                    >
                      Configure
                    </Button>
                  </div>
                  <PhoneTable />
                </div>
              ) : selectedMenuItem === "Select Pages" ? (
                <div>
                  <div className="d-flex flex-column justify-content-start align-items- mt-3 ms-3 me-5">
                    <div className="d-flex align-items-center justify-content-between">
                      <span className="ms-3 m-2" style={{ color: "#030229" }}>
                        List of Pages
                      </span>
                    </div>
                    {pagesList?.length > 0 ? (
                      pagesList.map((obj, index) => {
                        return (
                          <div key={index} className="d-flex justify-content-between align-items-center m-3">
                            <div className="d-flex align-items-center">
                              <div className="phoneLogoSprites listPhone me-3"></div>
                              <span style={{ fontWeight: 600 }}>{obj.name}</span>
                            </div>
                            <Switch
                              key={"switch" + index}
                              loading={isSwitchLoading[obj.id]}
                              className="addPhoneSwitch"
                              size="small"
                              value={obj.id}
                              checked={pageData.some((element) => {
                                return element.pageId === obj.id;
                              })}
                              onClick={(e) => {
                                handlePhoneNumberApi(obj, e);
                              }}
                            />
                          </div>
                        );
                      })
                    ) : numbersApiLoading === true ? (
                      <Skeleton className="m-2" paragraph={{ rows: 4 }} active />
                    ) : (
                      phoneNumberList?.length < 1 &&
                      numbersApiLoading === false && (
                        <Empty
                          style={{ marginTop: "30%" }}
                          description={`No ${appProperties?.providerCommonObj?.category === "messenger" ? "pages" : "numbers"} in your ${
                            rightServiceObj.rightServiceName
                          } Account.`}
                        />
                      )
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
      <ConfirmAlertModal
        onCancel={doOnCancel}
        open={deletePhoneModal.open}
        onConfirm={doOnConfirm}
        centered={true}
        alertIcon={<div className="phoneLogoSprites delete-icon me-4" />}
        titleContent={"Are you sure you want to disconnect?"}
        modalContent={`If you proceed, you won't be able to send or receive messages, and
              you might miss messages from customers. Confirm if you still want
              to proceed.`}
        loading={isSwitchLoading?.phoneNumber}
        buttonConfirm={"Yes, confirm"}
      />
    </>
  );
}
export default ConfigPhone;

const UpdatePhone = ({
  handlePhoneNumberApi,
  alphaSender,
  messagingService,
  setSelectedPhone,
  selectedMenuItem,
  selectedPhone,
  integId,
  appProperties,
  setAppProperties,
  setShowEditContent,
  msgSidList,
  addBtnLoading,
  setAddBtnLoading,
  setPhoneData,
}) => {
  const { fetchData } = useHttp();
  const [associatedSid, setAssociatedSid] = useState("");
  const [addBtnDisabled, setAddBtnDisabled] = useState(false);
  const handlePhoneUpdate = () => {
    setAddBtnLoading(true);
    let body = {
      alphaSender: alphaSender,
      isMessagingService: messagingService,
      phoneNumber: selectedPhone?.phoneNumber,
      friendlyName: selectedPhone?.friendlyName,
      messageServiceId: selectedPhone?.messageServiceId,
    };
    fetchData(`omessage/${integId}/updatePhone?associatedSid=${associatedSid}`, "PUT", body, appProperties)
      .then(function (response) {
        try {
          if (response) {
            response = JSON.parse(response);
            if (response?.data?.errorMessage) {
              failureNotification(response.data.errorMessage);
            } else {
              if (setPhoneData) {
                successNotification("Phone number updated. Continue engaging with clients.");
                setPhoneData((prev) => {
                  let prevObjs = prev.filter((obj) => obj.phoneNumber !== response?.data?.data?.phoneNumber);
                  return [...prevObjs, response?.data?.data];
                });
              }
            }
            setShowEditContent(false);
            setAddBtnLoading(false);
            setAppProperties((prevApp) => ({
              ...prevApp,
              savedNumbers: [
                ...(prevApp?.savedNumbers?.filter((obj) => obj?.phoneNumber !== response?.data?.data?.phoneNumber) || []),
                response?.data?.data,
              ],
            }));
          }
        } catch (error) {
          console.error("Error processing response:", error);
        }
      })
      .catch((err) => {
        setAddBtnLoading(false);
      });
  };

  const verifyMessagingService = (value) => {
    fetchData(`${integId}/verifyNumber?number=` + value, "GET", null, appProperties).then((response) => {
      if (response) {
        let savePhoneResp = JSON.parse(response);
        if (savePhoneResp?.data?.status === false) {
          const errorMessage = "No numbers connected !";
          const errorDescription = (
            <span>
              To use this messaging service, please add one or more numbers to it, in the Twilio console and return here.{" "}
              <Link
                href="https://help.twilio.com/articles/4402705042075-Managing-a-Messaging-Service-Sender-Pool#h_01H266YMP2NG092378YJ3PR315"
                target="_blank"
              >
                Read more
              </Link>
            </span>
          );
          failureNotification(errorMessage, errorDescription);
          setAddBtnDisabled(true);
        } else {
          setAddBtnDisabled(false);
          setSelectedPhone({
            ...selectedPhone,
            phoneNumber: value,
          });
        }
      }
    });
  };

  return (
    <Space direction="vertical" size="middle" style={{ width: "90%" }}>
      {selectedMenuItem !== "Phone number" ? (
        <div style={{ marginBottom: 16, marginTop: 26 }} className="edit-head">
          Choose Service
        </div>
      ) : (
        <div className="edit-head-phone">Friendly Name</div>
      )}
      {selectedMenuItem !== "MessagingService" ? (
        <div className="editPhoneLabel">
          {selectedMenuItem === "Phone number" ? "Phone Number" : "Alpha sender ID"}
          <Input
            value={selectedPhone?.phoneNumber}
            readOnly={selectedMenuItem === "Phone number" ? true : false}
            bordered={false}
            style={{ borderRadius: 8 }}
            className="editPhoneInputs"
            placeholder="Enter Alpha Sender ID"
            onChange={(event) => {
              const newPhoneNumber = event.target.value;
              setSelectedPhone({
                ...selectedPhone,
                phoneNumber: newPhoneNumber,
              });
            }}
          />
        </div>
      ) : null}
      {selectedMenuItem !== "AlphaSender" && appProperties.rightServiceName === "twilio" ? (
        <div className="editPhoneLabel">
          Messaging Service
          <Select
            value={associatedSid || selectedPhone?.messageServiceId}
            bordered={false}
            suffixIcon={<div className="actionIconsSprite fromNumberDropDownIcon" />}
            className="editPhoneInputs"
            style={{ borderRadius: 8 }}
            placeholder="Choose Messaging service"
            onSelect={(e) => {
              if (selectedMenuItem !== "MessagingService") {
                setAssociatedSid(e);
              } else {
                verifyMessagingService(e);
              }
            }}
          >
            {msgSidList?.map((number, index) => {
              return (
                <>
                  <Select.Option key={index} value={number.sid}>
                    {number.friendlyName + "(" + number.sid + ")"}
                  </Select.Option>
                </>
              );
            })}
          </Select>
        </div>
      ) : null}
      <div className="editPhoneLabel">
        Friendly name
        <Input
          value={selectedPhone?.friendlyName}
          bordered={false}
          className="editPhoneInputs"
          style={{ borderRadius: 8 }}
          placeholder="Enter friendly name"
          onChange={(e) => {
            setSelectedPhone({
              ...selectedPhone,
              friendlyName: e.target.value,
            });
          }}
        />
      </div>
      <Space className="d-flex flex-row-reverse justify-content-end align-items-center mt-3">
        <Button
          danger
          className="rounded-3 editPhoneCancelBtn"
          onClick={() => {
            setShowEditContent(false);
            setSelectedPhone({});
            setAddBtnDisabled(false);
          }}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          disabled={addBtnDisabled}
          className="rounded-3 editPhoneAddBtn"
          onClick={() => {
            selectedMenuItem === "Phone number" ? handlePhoneUpdate() : handlePhoneNumberApi("Table", true);
          }}
          loading={addBtnLoading}
        >
          Add
        </Button>
      </Space>
    </Space>
  );
};
